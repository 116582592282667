export const prostredi = {
    production: true,

    verze: 'Testovací',
    zakladniUrl: '/',

    url: 'https://test.eshop.fen.cz',
    urlFEN: 'https://test.fen.cz',
    urlCDD: 'https://test.cdd.fen.cz',
    urlPODO: 'https://test.podo.fen.cz',
    urlBackendEshopDetailObjednavky: 'https://test.api.eshop.fen.cz/fen-objednavky-detail-objednavky',
    urlBackendEshopDetailFaktury: 'https://test.api.eshop.fen.cz/fen-faktury-detail-faktury',
    urlBackendEshopSeznamObjednavek: 'https://test.api.eshop.fen.cz/fen-objednavky-seznam-objednavek',
    urlBackendEshopOnlinePlatby: 'https://test.api.eshop.fen.cz/fen-onlineplatby',
    urlBackendEshopProdukty: 'https://test.api.eshop.fen.cz/fen-produkty-detail-produktu',
    urlBackendEshopPredplatne: 'https://test.api.eshop.fen.cz/fen-predplatne',
    urlBackendEshopSlevoveKupony: 'https://test.api.eshop.fen.cz/fen-slevove-kupony',

    cddAdministraceBackendUrl: 'https://test.api.cddadmin.fen.cz/cdd-administrace',
    cddKeycloakUrl: 'https://test.auth.fen.cz/auth',

    cddRealm: 'CDD',
    cddClientId: 'administrace-cdd',
    cddClientSecret: 'beadf638-19d1-499c-bd48-fa9459eace33',

    administratorRealm: 'administrator',
    administratorClientId: 'cdd-administrace',
    administratorClientSecret: '7ab74e12-a20b-48e9-b79b-c1769957c503',

    eshopRealm: 'eshop',
    eshopClientId: 'administrace-eshopu',
    eshopClientSecret: 'a015e34f-c42e-441e-9978-54fec0b1f568',

    hlavicky: {
        'Content-Type': 'application/json'
    },
    hlavickyBackend: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
    },
    hlavickyBackendSGetOverride: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'X-HTTP-Method-Override': 'GET'
    },
};
